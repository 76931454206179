const TechnologiesComponent = () => {
    const technologies = [
        { name: 'PHP', image: '/images/php.svg', title: 'php' },
        { name: 'Laravel', image: '/images/laravel.svg', title: 'laravel' },
        { name: 'Livewire', image: '/images/livewire.svg', title: 'livewire' },
        { name: 'Vue.js', image: '/images/vue_js.svg', title: 'vue.js' },
        { name: 'React', image: '/images/react.svg', title: 'react' },
        { name: 'jQuery', image: '/images/jqeury.svg', title: 'jQuery' },
        { name: 'JavaScript', image: '/images/vanilla_js.svg', title: 'vanilla javascript' },
        { name: 'Tailwind', image: '/images/tailwind.svg', title: 'Tailwind' },
        { name: 'CSS', image: '/images/css.svg', title: 'CSS' },
        { name: 'SCSS', image: '/images/scss.svg', title: 'SCSS' },
        { name: 'MySQL', image: '/images/mysql.svg', title: 'MySQL' },
        { name: 'Redis', image: '/images/redis.svg', title: 'redis' },
        { name: 'Flutter', image: '/images/flutter.svg', title: 'Flutter' },
        { name: 'Dart', image: '/images/dart.svg', title: 'Dart' },
        { name: 'Python', image: '/images/python.svg', title: 'python' },
        { name: 'GitHub', image: '/images/github.svg', title: 'GitHub' },
    ];

    return (
        <div className="flex flex-wrap justify-center lg:justify-start gap-3">
            {technologies.map((tech, index) => (
                <div
                    key={index}
                    className="flex flex-col items-center justify-center shadow-[0_0_25px_rgba(0,0,0,0.1)] rounded-md px-5 py-3 max-w-[130px] w-full"
                >
                    <img src={tech.image} alt={tech.name} title={tech.title} />
                    <div>{tech.name}</div>
                </div>
            ))}
        </div>
    );

}

export default TechnologiesComponent;