import React, { useState, useEffect } from 'react';
import _debounce from 'lodash.debounce';
import Header from './components/Header';
import Footer from './components/Footer';
import HeroComponent from './components/HeroComponent';
import ExperienceComponent from './components/ExperienceComponent';
import PorfolioComponent from './components/PorfolioComponent';

function App() {
  const [scrollTo, setScrollTo] = useState(1);
  const [bgColor, setBgColor] = useState('#ffffff');
  const [textColor, setTextColor] = useState('#000000');
  const [currentId, setCurrentId] = useState('1');

  useEffect(() => {
    let isScrolling = false;

    const handleScroll = () => {
      if (!isScrolling) {
        isScrolling = true;
        requestAnimationFrame(() => {
          const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting && entry.target.id !== 'root') {
                let currentComponentId = parseInt(entry.target.id);
                if (!isNaN(currentComponentId)) {
                  setScrollTo(currentComponentId);
                }
              }
            });
          }, { threshold: 0.3 });

          const elemsWithIds = document.querySelectorAll("*[id]:not(:root)");
          elemsWithIds.forEach(elem => observer.observe(elem));

          isScrolling = false;
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const targetElement = document.getElementById(String(scrollTo));
    if (targetElement) {
      setCurrentId(String(scrollTo));
      switch (scrollTo) {
        case 1:
          setBgColor('#ffffff');
          setTextColor('#4A83E7');
          break;

        case 2:
          setBgColor('#ebf3fa');
          setTextColor('#4A83E7');
          break;

        case 3:
          setBgColor('#d1e3f6');
          setTextColor('#4A83E7');
          break;

        case 4:
          setBgColor('#d4e2f2');
          setTextColor('#4A83E7');
          break;
      }
    }
  }, [scrollTo]);

  return (
    <div className="App">
      <Header textColor={textColor} bgColor={bgColor} id={currentId} />
      <div>
        <HeroComponent textColor={textColor} bgColor={bgColor} currentId="1" />
        <ExperienceComponent textColor={textColor} bgColor={bgColor} currentId="2" />
        {/* <PorfolioComponent textColor={textColor} bgColor={bgColor} currentId="3" /> */}
      </div>
      <Footer textColor={textColor} bgColor={bgColor} currentId="4" />
    </div>
  );
}

export default App;
