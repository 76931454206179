import React from 'react';
import { useState, useEffect } from 'react';
import MenuItem from './MenuItem';

const Header = (props) => {
    const bgColor = props.bgColor;
    const textColor = props.textColor;
    const [menuOpened, setMenuOpened] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState(props.id);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);

        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
            setActiveMenuItem(sectionId);
        }
    };

    const handleClick = () => {
        setMenuOpened(!menuOpened);
    };


    useEffect(() => {
        setActiveMenuItem(props.id);
    }, [props.id]);

    useEffect(() => {
        if (menuOpened) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            // Cleanup to reset the body's overflow when the component unmounts
            document.body.style.overflow = 'auto';
        };
    }, [menuOpened]);

    return (
        <div className='sticky top-0 z-10'>
            <div style={{
                backgroundColor: bgColor,
                color: textColor,
                transition: 'background-color 0.3s ease, color 0.3s ease'
            }} className='flex shadow-[0_0_25px_rgba(0,0,0,0.1)]'>
                <div className='flex justify-between items-center container mx-auto px-3 lg:px-0 py-5'>
                    <a href='#' aria-label="ScrollTop">
                        <svg width="30" height="13" viewBox="0 0 30 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.968 3.176H17.168V10.376H17.968V13H11.92V10.376H12.688V4.776L9.968 13H7.6L4.8 4.856V10.376H5.568V13H0.32V10.376H1.12V3.176H0.32V0.551999H7.648L9.312 5.544L10.912 0.551999H17.968V3.176ZM18.6013 10.376H19.4013V3.176H18.6013V0.551999H25.6253C27.0013 0.551999 28.0519 0.850666 28.7773 1.448C29.5026 2.03467 29.8653 2.98933 29.8653 4.312C29.8653 5.63467 29.5026 6.59467 28.7773 7.192C28.0519 7.77867 27.0013 8.072 25.6253 8.072H23.8813V10.376H25.4173V13H18.6013V10.376ZM24.4253 5.8C25.0439 5.8 25.3533 5.45867 25.3533 4.776V4.2C25.3533 3.848 25.2733 3.592 25.1133 3.432C24.9639 3.26133 24.7346 3.176 24.4253 3.176H23.8813V5.8H24.4253Z" fill="currentColor" />
                        </svg>
                    </a>
                    <div className='hidden lg:flex'>
                        <ul className="flex items-center gap-2.5">
                            <MenuItem
                                sectionId='1'
                                label='Intro'
                                active={activeMenuItem === '1'}
                                onClick={() => scrollToSection('1')}
                                textColor={textColor}
                            />

                            <MenuItem
                                sectionId='2'
                                label='About me'
                                active={activeMenuItem === '2'}
                                onClick={() => scrollToSection('2')}
                                textColor={textColor}
                            />

                            {/* <MenuItem
                                sectionId='3'
                                label='Portfolio'
                                active={activeMenuItem === '3'}
                                onClick={() => scrollToSection('3')}
                                textColor={textColor}
                            /> */}

                            <MenuItem
                                sectionId='4'
                                label='Contact'
                                active={activeMenuItem === '4'}
                                onClick={() => scrollToSection('4')}
                                textColor={textColor}
                            />
                        </ul>
                    </div>
                    <button onClick={handleClick} className="flex lg:hidden flex-col justify-center items-center" aria-label="menu-button">
                        <span style={{ backgroundColor: textColor }} className={`block transition-all duration-300 ease-out transform h-1 w-6 rounded-sm ${menuOpened ? 'rotate-45 translate-y-1' : '-translate-y-0.5'}`}>
                        </span>
                        <span style={{ backgroundColor: textColor }} className={`block transition-all duration-300 ease-out transform h-1 w-6 rounded-sm my-0.5 ${menuOpened ? 'opacity-0' : 'opacity-100'}`}>
                        </span>
                        <span style={{ backgroundColor: textColor }} className={`block transition-all duration-300 ease-out transform h-1 w-6 rounded-sm ${menuOpened ? '-rotate-45 -translate-y-1' : 'translate-y-0.5'}`}>
                        </span>
                    </button>
                </div>
            </div>
            {
                menuOpened && (
                    <div className={`w-full h-dvh z-10 fixed top-[56px] left-[50%] transform -translate-x-1/2 transition-all duration-300 ease-in-out ${menuOpened ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-full'}`}
                        style={{
                            backgroundColor: textColor,
                            color: bgColor
                        }}>
                        <div className='container mx-auto px-3 lg:px-0 h-full flex justify-center'>
                            <ul className="flex flex-col justify-center items-center gap-5">
                                <MenuItem
                                    sectionId='1'
                                    label='Intro'
                                    active={activeMenuItem === '1'}
                                    onClick={() => { scrollToSection('1'); handleClick(); }}
                                    textColor={bgColor}
                                />

                                <MenuItem
                                    sectionId='2'
                                    label='About me'
                                    active={activeMenuItem === '2'}
                                    onClick={() => { scrollToSection('2'); handleClick(); }}
                                    textColor={bgColor}
                                />

                                {/* <MenuItem
                                    sectionId='3'
                                    label='Portfolio'
                                    active={activeMenuItem === '3'}
                                    onClick={() => { scrollToSection('3'); handleClick(); }}
                                    textColor={bgColor}
                                /> */}

                                <MenuItem
                                    sectionId='4'
                                    label='Contact'
                                    active={activeMenuItem === '4'}
                                    onClick={() => { scrollToSection('4'); handleClick(); }}
                                    textColor={bgColor}
                                />
                            </ul>
                        </div>
                    </div>

                )
            }
        </div >
    );
}

export default Header;