const MenuItem = ({ sectionId, label, active, onClick, textColor }) => {
    const hoverStyles = {
        backgroundColor: textColor,
        transform: active ? 'scaleX(1)' : 'scaleX(0)',
    };

    return (
        <li
            onClick={onClick}
            className={`font-normal cursor-pointer text-lg relative w-max one group hover:${active ? 'hover-active' : ''}`}
        >
            <span>{label}</span>
            <span
                style={{
                    backgroundColor: textColor,
                    transition: 'transform 0.3s ease',
                    ...hoverStyles,
                }}
                className="absolute -bottom-1 left-0 w-full h-0.5"
            ></span>
        </li>
    );
};

export default MenuItem;
