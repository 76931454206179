import { useRef } from "react";
import TechnologiesComponent from "./TechnologiesComponent";

const ExperienceComponent = (props) => {
    const currentId = props.currentId;
    const bgColor = props.bgColor;
    const textColor = props.textColor;
    const containerRef = useRef(false);

    const scrollToBottom = () => {
        window.scrollTo(0, document.body.scrollHeight);
    };

    return (
        <div id={currentId} style={{
            backgroundColor: bgColor,
            color: textColor,
            transition: 'background-color 0.3s ease, color 0.3s ease'
        }}>
            <div
                className="container mx-auto px-3 lg:px-0 pt-20 lg:pt-30">

                <div className="flex gap-5">
                    <div className="w-full hidden lg:block relative">
                        <div className="sticky top-[120px]">
                            <img className="w-full rounded-md" src="/images/IMG_8339.png" title="It is me - Matas Puidokas" alt="Matas image" />
                            <a className="text-base mt-3 block" href="mailto: mataspuidokas@gmail.com">mataspuidokas@gmail.com</a>
                            <a className="text-base mt-3 block" href="tel: +37062636020">+37062636020</a>
                        </div>
                    </div>
                    <div>
                        <h2 className="text-5xl lg:text-6xl drop-shadow-md">ABOUT ME</h2>
                        <div className="w-full block lg:hidden mt-10 ">
                            <img className="rounded-md mx-auto" src="/images/IMG_8339.png" title="It is me - Matas Puidokas" alt="Matas image" />
                            <a className="text-base mt-3 block text-center" href="mailto: mataspuidokas@gmail.com">mataspuidokas@gmail.com</a>
                            <a className="text-base mt-3 block text-center" href="tel: +37062636020">+37062636020</a>
                        </div>
                        <div>
                            <div className="w-full mt-5 flex flex-col gap-3 text-base">
                                <span>In June 2021, I successfully graduated from Vilnius Tech University, earning my bachelor's degree. Throughout my studies, I gained expertise in creating systems, reading electronic circuits, understanding IT networks, and internet protocols. I also acquired a solid foundation in artificial intelligence and learned how to write specifications for various project types. Additionally, I completed full-stack studies at the Baltic Institute of Technology, collaborating with fantastic individuals and enhancing my teamwork and project completion skills.</span>

                                <span>During my time in the USA under the work and travel program, I took on more responsibilities, working with business clients, addressing household issues, and improving my English skills.</span>

                                <span>Upon returning to Lithuania, I dedicated myself to continuous learning and skill improvement to become a better version of myself. In my final university year, I joined "We Deliver" agency as a full-stack developer, where I worked for over 2 years. During this period, I engaged with various content management systems like WordPress and Magento 2, constructing web pages, mobile apps, and internal systems from the ground up. Each project involved a dedicated team, allowing me to deepen my knowledge and refine my skills not only during the tasks but during the meetings as well.</span>

                                <span>Currently, I am actively working on personal projects and learning new things, seeking to tackle even more complex challenges than those I've encountered thus far.</span>
                            </div>
                            <div className="flex items-center justify-center gap-3 mt-5">
                                <a
                                    style={{
                                        border: `1px solid ${textColor}`,
                                        backgroundColor: bgColor,
                                        color: textColor,
                                        transition: 'background-color 0.3s, color 0.3s',
                                    }}
                                    className="py-3 px-5 rounded-md"
                                    href='#contacts'
                                    onMouseOver={(e) => {
                                        e.target.style.backgroundColor = textColor;
                                        e.target.style.color = bgColor;
                                    }}
                                    onMouseOut={(e) => {
                                        e.target.style.backgroundColor = bgColor;
                                        e.target.style.color = textColor;
                                    }}
                                    onClick={scrollToBottom}
                                >
                                    Get in touch
                                </a>
                                <a
                                    style={{
                                        border: `1px solid ${textColor}`,
                                        backgroundColor: textColor,
                                        color: bgColor,
                                        transition: 'background-color 0.3s, color 0.3s',
                                    }}
                                    onMouseOver={(e) => {
                                        e.target.style.backgroundColor = bgColor;
                                        e.target.style.color = textColor;
                                    }}
                                    onMouseOut={(e) => {
                                        e.target.style.backgroundColor = textColor;
                                        e.target.style.color = bgColor;
                                    }}
                                    className="py-3 px-5 rounded-md"
                                    href="/files/Matas_Puidokas_Resume.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Resume
                                </a>
                            </div>
                        </div>
                        <div className="mt-10">
                            <h3 className="text-2xl lg:text-3xl text-center lg:text-left drop-shadow-md">Experience</h3>
                            <div className="flex flex-col justify lg:flex-row justify-between gap-5 mt-5">
                                <div className="w-full">
                                    <TechnologiesComponent />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ExperienceComponent;
