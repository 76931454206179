import { useState, useEffect, useRef } from 'react';
import emailjs from '@emailjs/browser';

const Footer = (props) => {
    const currentId = props.currentId;
    const bgColor = props.bgColor;
    const textColor = props.textColor;

    const form = useRef();
    const [formClicked, setFormClicked] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [isPending, setIsPending] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleLoaded = () => {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = "https://www.google.com/recaptcha/api.js?render=6LczD1YpAAAAAP3OH9xNtlMPcI8VQbDVHTCt1yA0";

            script.onload = () => {
                window.grecaptcha.ready(() => {
                    window.grecaptcha
                        .execute("6LczD1YpAAAAAP3OH9xNtlMPcI8VQbDVHTCt1yA0", { action: "homepage" })
                        .then((token) => resolve(token))
                        .catch((error) => reject(error));
                });
            };

            script.onerror = (error) => reject(error);

            document.body.appendChild(script);
        });
    };

    useEffect(() => {
        if (formClicked) {
            const loadRecaptcha = async () => {
                const script = document.createElement("script");
                script.src = "https://www.google.com/recaptcha/api.js?render=6LczD1YpAAAAAP3OH9xNtlMPcI8VQbDVHTCt1yA0";
                document.body.appendChild(script);

                try {
                    await handleLoaded().then(token => {
                        setRecaptchaToken(token);
                        if (token) {
                            sendEmail(token);
                        } else {
                            // Handle the case when reCAPTCHA token is not available
                            setError(true);
                            setErrorMessage("reCAPTCHA verification failed");
                            setIsPending(false);
                        }
                    })
                } catch (error) {
                    // Handle error if reCAPTCHA fails to load
                    console.error("Error loading reCAPTCHA:", error);
                    setRecaptchaToken(null);
                    setError(true);
                    setErrorMessage("An error occurred while loading reCAPTCHA.");
                    setIsPending(false);
                }
            };

            loadRecaptcha();
        }
    }, [formClicked]);

    const sendEmail = (token = recaptchaToken) => {
        try {
            if (token) {
                const result = emailjs.sendForm('service_gli62ba', 'template_lcrfyvm', form.current, 'VdgEvVzRYHnaFU37a', { 'g-recaptcha-response': token });

                if (result) {
                    setSuccess(true);
                    setName('');
                    setEmail('');
                    setMessage('');
                    setSuccessMessage("The message has been sent.");
                }
            } else {
                // Handle the case when reCAPTCHA token is not available
                setError(true);
                setErrorMessage("reCAPTCHA verification failed");
            }
        } catch (error) {
            // Handle email sending error
            setError(true);
            setErrorMessage(error.text || "An error occurred while sending the email.");
        } finally {
            setIsPending(false);
        }
    }

    const isValidEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const isFormValid = () => {
        if (!name || !message || !email) {
            setError(true);
            setErrorMessage('All fields are required');
            return false;
        }

        if (!isValidEmail(email)) {
            setError(true);
            setErrorMessage('Please enter a valid email address');
            return false;
        }

        if (!name) {
            setError(true);
            setErrorMessage('Please enter a subject');
            return false;
        }

        setError(false);
        setErrorMessage('');

        setSuccess(false);
        setSuccessMessage('');
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isFormValid()) {
            setIsPending(true);
            setFormClicked(true);
            if (recaptchaToken) {
                sendEmail();
            }
        }
    };


    return (
        <div id={currentId} style={{
            backgroundColor: bgColor,
            color: textColor,
            transition: 'background-color 0.3s ease, color 0.3s ease',
        }}>
            <div className="container mx-auto px-3 lg:px-0 pt-20 lg:pt-30">
                <h2 className="text-5xl lg:text-6xl drop-shadow-md pt-3">CONTACT</h2>
                <div className="flex flex-col lg:flex-row justify-between gap-5 mt-5">
                    <div className='w-full'>
                        <h3 className='text-2xl lg:text-3xl drop-shadow-md'>Drop Me a Message</h3>
                        <p className='py-10 text-base'>If you have any questions or would like to work with me, feel free to contact me. You can reach me by phone, send a text via email, or use the form provided in this section.</p>
                    </div>
                    <div style={{ border: `1px solid ${textColor}` }} className='w-full padding px-3 py-5 rounded-md'>
                        <form ref={form} onSubmit={handleSubmit}>
                            <div className="relative z-0 w-full mb-5 group">
                                <input
                                    aria-labelledby="name-label"
                                    name="name"
                                    className='block py-2.5 px-0 w-full bg-transparent border-0 border-b-2 border-white appearance-non focus:outline-none focus:ring-0 peer'
                                    type="text"
                                    required
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <label id="name-label" htmlFor="name" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Your name</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input
                                    aria-labelledby="email-label"
                                    name="email"
                                    className='block py-2.5 px-0 w-full bg-transparent border-0 border-b-2 border-white appearance-non focus:outline-none focus:ring-0 peer'
                                    type="text"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <label id="email-label" htmlFor="email" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Your email</label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <textarea
                                    aria-labelledby="message-label"
                                    name="message"
                                    className='block py-2.5 px-0 w-full bg-transparent border-0 border-b-2 border-white appearance-non focus:outline-none focus:ring-0 peer'
                                    required
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                >
                                </textarea>
                                <label id="message-label" htmlFor="message" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Your message</label>
                            </div>
                            {error && <p className='text-red-500 block mb-3'>{errorMessage}</p>}
                            {!isPending && <button
                                style={{
                                    border: `1px solid ${textColor}`,
                                    backgroundColor: textColor,
                                    color: bgColor,
                                    transition: 'background-color 0.3s, color 0.3s',
                                }}
                                onMouseOver={(e) => {
                                    e.target.style.backgroundColor = bgColor;
                                    e.target.style.color = textColor;
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.backgroundColor = textColor;
                                    e.target.style.color = bgColor;
                                }} className='w-full py-3 px-5 rounded-md' aria-label="send">Send</button>}
                            {isPending && <button disabled aria-label="sending">Sending...</button>}
                            <div
                                className="g-recaptcha"
                                data-sitekey="6LczD1YpAAAAAP3OH9xNtlMPcI8VQbDVHTCt1yA0"
                                data-size="invisible"
                            ></div>
                        </form>
                        {success ? (
                            <p className='text-green-500 block mt-3'>{successMessage}</p>
                        ) : (
                            <p className='text-sm block mt-3'>
                                *Using a free email provider's account, there might be limitations on the number of emails that can be sent per month.
                                If this limit is reached, you can always contact me directly using my credentials.
                            </p>
                        )}
                    </div>
                </div>
                <div
                    className="text-base container mx-auto px-3 lg:px-0 text-center pb-5 pt-20 lg:pt-30">
                    Matas Puidokas &copy; {new Date().getFullYear()}
                </div >
            </div>
        </div >
    );
}

export default Footer;