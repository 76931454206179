const HeroComponent = ({ currentId, bgColor, textColor }) => {

    return (
        <div id={currentId} style={{
            backgroundColor: bgColor,
            color: textColor,
            transition: 'background-color 0.3s ease, color 0.3s ease'
        }}>
            <div
                className="container mx-auto px-3 lg:px-0 pt-20 lg:pt-30">
                <div className="flex flex-col lg:flex-row justify-between gap-5">
                    <div className="w-full flex flex-col justify-center">
                        <p className="text-base drop-shadow-md">I'm a</p>
                        <h1 className="text-5xl lg:text-8xl drop-shadow-md">FULL STACK SOFTWARE DEVELOPER.</h1>
                    </div>
                    <a className="w-full" href="/" aria-label="Home">
                        <svg className="mx-auto mt-10 lg:mt-0 max-w-[200px] lg:max-w-full" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 300 300">
                            <g>
                                <g>
                                    <g>
                                        <path d="M111.29,87.097h-6.561c9.89-7.989,16.239-20.197,16.239-33.871V37.519C120.968,16.834,104.134,0,83.448,0H71.39
				C50.705,0,33.871,16.834,33.871,37.519v15.706c0,13.674,6.348,25.882,16.239,33.871h-6.561C19.534,87.097,0,106.635,0,130.645
				v48.387c0,16.011,13.021,29.032,29.032,29.032V300h96.774v-91.935c16.011,0,29.032-13.021,29.032-29.032v-48.387
				C154.839,106.635,135.305,87.097,111.29,87.097z M43.548,37.519c0-15.348,12.489-27.842,27.842-27.842h12.058
				c15.353,0,27.842,12.494,27.842,27.842v1.868c-2.627-2.216-4.994-4.79-6.982-7.776c-3.092-4.645-8.269-7.418-13.858-7.418H64.384
				c-5.584,0-10.766,2.773-14.023,7.669c-1.892,2.84-4.215,5.356-6.813,7.539V37.519z M43.548,53.226V51.15
				c5.918-3.489,11.071-8.226,15.034-14.177c1.297-1.94,3.465-3.102,5.806-3.102h26.066c2.337,0,4.51,1.161,5.971,3.358
				c3.818,5.724,8.952,10.437,14.865,13.921v2.076c0,18.677-15.198,33.871-33.871,33.871S43.548,71.903,43.548,53.226z
				 M95.584,96.774c-2.671,5.618-9.765,9.677-18.165,9.677s-15.494-4.06-18.165-9.677H95.584z M29.032,198.387
				c-10.674,0-19.355-8.681-19.355-19.355v-38.71h19.355V198.387z M72.581,290.323H38.71v-9.677h33.871V290.323z M116.129,290.323
				H82.258v-9.677h33.871V290.323z M116.13,270.968H82.258v-53.226h-9.677v53.226H38.71v-48.876
				c11.027-2.25,19.355-12.019,19.355-23.705h38.71c0,11.681,8.327,21.455,19.355,23.705V270.968z M116.129,154.839v33.871H38.71
				v-33.871H116.129z M38.71,145.161v-9.677h77.419v9.677H38.71z M116.129,198.387v13.626c-5.618-2.003-9.677-7.326-9.677-13.626
				H116.129z M48.387,198.387c0,6.3-4.06,11.623-9.677,13.626v-13.626H48.387z M145.161,179.032c0,10.674-8.68,19.355-19.355,19.355
				v-58.065h19.355V179.032z M125.806,130.645v-9.677h-9.677v4.839H38.71v-4.839h-9.677v9.677H9.677
				c0-18.677,15.198-33.871,33.871-33.871h5.424c2.7,11.027,14.424,19.355,28.447,19.355s25.747-8.327,28.447-19.355h5.424
				c18.673,0,33.871,15.194,33.871,33.871H125.806z"/>
                                        <path d="M179.032,145.161h29.032v9.677h-14.516v9.677h14.516h48.387h14.516v-9.677h-14.516v-9.677h29.032
				c8.003,0,14.516-6.513,14.516-14.516V62.903c0.001-8.003-6.512-14.516-14.515-14.516H179.032
				c-8.003,0-14.516,6.513-14.516,14.516v67.742C164.516,138.648,171.029,145.161,179.032,145.161z M246.774,154.839h-29.032v-9.677
				h29.032V154.839z M174.194,62.903c0-2.666,2.167-4.838,4.838-4.838h106.452c2.671,0,4.839,2.173,4.839,4.839v53.226H174.194
				V62.903z M174.194,125.806h116.129v4.839c0,2.666-2.168,4.839-4.839,4.839h-29.032h-48.387h-29.032
				c-2.671,0-4.839-2.173-4.839-4.839V125.806z"/>
                                        <path d="M164.516,174.193v58.065H300v-58.065H164.516z M290.323,222.581H174.194v-38.71h116.129V222.581z" />
                                        <rect x="179.032" y="188.71" width="9.677" height="9.677" />
                                        <rect x="198.387" y="188.71" width="9.677" height="9.677" />
                                        <rect x="217.742" y="188.71" width="9.677" height="9.677" />
                                        <rect x="237.097" y="188.71" width="9.677" height="9.677" />
                                        <rect x="256.452" y="188.71" width="9.677" height="9.677" />
                                        <rect x="275.806" y="188.71" width="9.677" height="9.677" />
                                        <rect x="179.032" y="208.065" width="9.677" height="9.677" />
                                        <rect x="198.387" y="208.065" width="67.742" height="9.677" />
                                        <rect x="275.806" y="208.065" width="9.677" height="9.677" />
                                        <polygon points="214.321,69.16 196.384,87.097 214.321,105.034 221.163,98.192 210.068,87.097 221.163,76.002 			" />
                                        <polygon points="243.353,76.002 254.448,87.097 243.353,98.192 250.195,105.034 268.132,87.097 250.195,69.16 			" />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default HeroComponent;